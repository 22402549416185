import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BtnDirective } from './btn-directive/btn.directive';

@NgModule({
	declarations: [BtnDirective],
	exports: [BtnDirective],
	imports: [CommonModule],
})
export class BtnModule {}
