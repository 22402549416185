import { Directive, HostBinding, Input } from '@angular/core';
import { BtnSize, BtnType } from '../btn.model';

@Directive({
	selector: '[appBtn]',
})
export class BtnDirective {
	@Input() size: BtnSize = 'small';
	@Input() buttonType: BtnType = 'primary';
	@Input() disabled: boolean | null = false;

	constructor() {}

	@HostBinding('class') get classes(): string {
		return `button button--${this.buttonType}${this.disabled ? '-disabled' : ''}`;
	}
}
